@import "../../../styles/variables.scss";

.mainQuestionContainer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  gap: 30px;
  background-color: $white;
  text-align: center;
  border-radius: 15px;
  border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.5);
  @media screen and (max-width: 767px) {
    background-color: $white;
    gap: 30px;
    margin: 10px 0 20px;
  }
}

.confirmedOfferContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: $veryLight;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  .transitionContainer {
    display: flex;
    overflow: hidden;
    max-width: 600px;
    position: relative;
    width: 100%;
    min-height: 420px;
    flex-grow: 1;
    .swipe {
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: absolute;
      padding: 60px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      &:global(.question-swipe-enter) {
        opacity: 0;
        left: 150%;
      }
      &:global(.question-swipe-enter-active) {
        opacity: 1;
        left: 50%;
        transition:
          transform 2s cubic-bezier(0.16, 1, 0.3, 1),
          opacity 2s cubic-bezier(0.16, 1, 0.3, 1),
          max-width 2s cubic-bezier(0.16, 1, 0.3, 1),
          left 2s cubic-bezier(0.16, 1, 0.3, 1);
      }
      &:global(.question-swipe-exit) {
        opacity: 1;
        left: 50%;
      }
      &:global(.question-swipe-exit-active) {
        opacity: 0;
        left: -150%;
        transition:
          transform 2s cubic-bezier(0.16, 1, 0.3, 1),
          opacity 2s cubic-bezier(0.16, 1, 0.3, 1),
          max-width 2s cubic-bezier(0.16, 1, 0.3, 1),
          left 2s cubic-bezier(0.16, 1, 0.3, 1);
      }
      @media screen and (max-width: 1024px) {
        padding: 40px;
      }
      @media screen and (max-width: 767px) {
        padding: 25px;
      }
    }
    &.back :global(.question-swipe-enter) {
      left: -150%;
    }
    &.back :global(.question-swipe-enter-active) {
      left: 50%;
    }
    &.back :global(.question-swipe-exit) {
      left: 50%;
    }
    &.back :global(.question-swipe-exit-active) {
      left: 150%;
    }
    @media screen and (max-width: 1024px) {
      min-height: 400px;
      padding: 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    justify-content: space-between;
  }
}

.titleQuestionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    @include font-acumin-b;
    display: flex;
    font-size: 27px;
    letter-spacing: 0;
    margin: 0;
    color: $primaryColor;
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 12px;
    color: $primaryColor;
    margin: 0;
  }
}

.answerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.optionContainer {
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  background: transparent;
  row-gap: 8px;
  border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.4);
  transition:
    box-shadow 0.3s,
    transform 0.2s;
  &:hover {
    box-shadow: 0 5px 4px -2px rgba($color: $helpTextColorRgb, $alpha: 0.3);
    transform: translateY(-4px);
  }
  &:focus {
    background-color: transparent;
  }
  &:active {
    background: transparent;
  }
  h2 {
    display: flex;
    font-family: $circularBold;
    font-size: 14px;
    color: $primaryColor;
    text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  p {
    display: flex;
    font-family: $circular;
    font-size: 12px;
    color: $primaryColor;
    margin: 0;
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 14px 20px;
  }
}

.fundingSlider {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  .sliderValContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    color: $primaryColor;
    justify-content: center;
    font-family: $circularBold;
    text-transform: uppercase;
    :global(.odometer) {
      font-family: $circularBold;
      letter-spacing: 0;
    }
    .sliderBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: $ctaColor;
      border: none;
      padding: 0;
      height: 40px;
      width: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
  .sliderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    text-transform: uppercase;
    .minMax {
      font-size: 16px;
      font-family: $circularBold;
      color: $primaryColor;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .sliderSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $ctaColor;
    border-radius: 50px;
    font-family: $circularBold;
    font-size: 14px;
    color: $white;
    padding: 14px 20px;
    border: none;
  }
}

.highlightedOption {
  border: 1px solid $primaryColor;
  box-shadow: 0 5px 4px -2px rgba($color: $helpTextColorRgb, $alpha: 0.3);
  transform: translateY(-4px);
  h2,
  p {
    color: $primaryColor;
  }
}

.goBackBtn {
  font-family: $circular;
  font-size: 14px;
  color: $primaryColor;
  margin: 0;
  text-decoration: underline;
  background: transparent;
  border: none;
  margin-top: 20px;
}

.mobOfferRange {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    margin: 0;
    svg {
      width: 13px;
      height: 13px;
    }
  }
  .rangeNumbers {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: $circularBold;
    color: $sidebarPrimaryBackgroundColor;
    text-transform: lowercase;
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.recommendedConiner {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  align-items: center;
  max-width: 1075px;
}
.recommendedDealContainer {
  display: flex;
  width: 100%;
  gap: 25px;
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
  .mobileGoBackBtn {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
      .goBackBtn {
        margin: 0;
      }
    }
  }
}

.dealCard {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  border: 2px solid $light;
  box-shadow: 0px 3px 6px 0px #0000000b;
  min-width: 280px;
  min-height: 512px;
  background-color: white;
  width: 100%;
  max-width: 340px;
  .dealHeader {
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 15px;
    align-items: center;
    border-bottom: 2px solid $light;
    .label {
      font-family: $circularSemiBold;
      font-size: 14px;
      color: $black;
    }
    .totalAdvance {
      @include font-acumin-b;
      font-size: 50px;
      line-height: 45px;
      text-transform: uppercase;
      color: $primaryColor;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      flex-direction: row;
      padding: 15px 20px;
      justify-content: space-between;
      .label {
        font-size: 12px;
      }
      .totalAdvance {
        font-size: 32px;
        line-height: 28px;
      }
    }
  }
  .dealDetails {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    gap: 15px;
    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 0;
      margin: 0;
      gap: 8px;
    }

    li {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      cursor: default;
    }

    .moreDetailContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 0px;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
      &.active {
        max-height: 900px;
      }
    }

    .label {
      display: flex;
      font-family: $circular;
      font-size: 14px;
      gap: 5px;
      span {
        cursor: pointer;
      }
      svg {
        width: 10px;
        height: 10px;
        path {
          fill: $primaryColor;
        }
      }
      &.textTooltip {
        svg {
          margin-top: 5px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

    .value {
      font-family: $circularBold;
      color: $primaryColor;
      font-size: 14px;
      text-align: right;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    .amount {
      text-transform: uppercase;
    }

    .collapseArrow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: transform 0.3s;
      cursor: pointer;
      .showMore {
        font-family: $circularBold;
        font-size: 14px;
        color: $primaryColor;
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      &.arrowDown {
        svg {
          transform: translateY(-5px) rotateX(0deg) rotateZ(180deg);
          margin-top: 10px;
        }
      }
      svg {
        path {
          fill: $primaryColor;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 0px;
        transition: transform 0.3s;
      }
    }

    @media screen and (max-width: 767px) {
      gap: 5px;
    }
  }
  .dealPartner {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    gap: 10px;
    margin-top: auto;
    border-top: 2px solid $light;
    .partnerDetails {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .label {
        display: flex;
        font-family: $circular;
        font-size: 14px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      .value {
        font-family: $circularBold;
        color: $primaryColor;
        font-size: 14px;
        text-align: right;
        img {
          height: 20px;
          max-width: 35px;
          object-fit: contain;
          object-position: left;
        }
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .featureCtn {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      .featureBtn {
        font-family: $circular;
        font-size: 12px;
        color: $primaryColor;
        margin: 0;
        text-decoration: underline;
        background: transparent;
        border: none;
        cursor: pointer;
        text-align: center;
      }
    }
    .option {
      display: flex;
      align-items: center;
      column-gap: 10px;

      img {
        height: 20px;
        max-width: 35px;
        object-fit: contain;
        object-position: left;
      }
    }
    :global(.distributor__control--menu-is-open) {
      border-radius: 7px 7px 0 0;
      border-bottom: none;
    }

    :global(.distributor__control--is-disabled) {
      opacity: 0.6;
    }

    :global(.distributor__menu) {
      animation: fadeInDistributorMenu 0.2s ease-in-out;
    }

    :global(.distributor__option) {
      font-size: 14px;
      padding: 6px 12px;
    }

    :global(.distributor__menu-list) {
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: $veryLight;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba($color: $grey, $alpha: 0.4);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba($color: $grey, $alpha: 0.3);
      }
    }

    :global(.distributor__indicator) {
      svg {
        fill: rgba($color: $primaryColorRgb, $alpha: 0.5);
      }
    }

    @keyframes fadeInDistributorMenu {
      0% {
        max-height: 0;
        opacity: 0;
      }

      50% {
        opacity: 0.2;
      }

      100% {
        max-height: 200px;
        opacity: 1;
      }
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    :global(.form-group) {
      margin-bottom: 0px;
      > div {
        margin-bottom: 0px !important;
        padding-top: 0px !important;
      }
    }
  }
  .dealButton {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    font-size: 14px;
    gap: 5px;
    border-top: 2px solid $light;
    .primary {
      font-family: $circularBold;
      color: $white;
      border-radius: 25px;
      text-transform: uppercase;
      width: 100%;
      height: 50px;
      background-color: $ctaColor;
      border: none;
      &:disabled {
        background-color: rgba($color: $ctaColorRgb, $alpha: 0.5);
        cursor: not-allowed;
      }
      @media screen and (max-width: 767px) {
        color: $white;
        height: 36px;
      }
    }
    .secondary {
      font-family: $circularBold;
      color: $ctaColor;
      width: 100%;
      background-color: transparent;
      border: none;
      text-transform: uppercase;
      outline: none;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 25px;
      font-size: 12px;
      gap: 10px;
    }
  }
  .emptyDiv {
    height: 20px;
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
  }
  @media screen and (max-width: 767px) {
    min-height: unset;
  }
}

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $white;
  border-radius: 15px;
  box-shadow: 0px 3px 6px 0px #0000000b;
  padding: 15px 25px;
  .backBtnContainer {
    display: flex;
    align-items: center;
    .goBackBtn {
      font-family: $circular;
      font-size: 14px;
      color: $primaryColor;
      margin: 0;
      text-decoration: underline;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .offersBtnContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    .primary {
      min-width: 185px;
      font-family: $circularBold;
      color: $ctaColor;
      border-radius: 25px;
      text-transform: uppercase;
      width: 100%;
      height: 40px;
      background-color: transparent;
      border: 1.5px solid $ctaColor;
      outline: none;
      &:disabled {
        cursor: not-allowed;
        border: 1.5px solid rgba($color: $ctaColorRgb, $alpha: 0.6);
        color: rgba($color: $ctaColorRgb, $alpha: 0.6);
      }
    }
    .secondary {
      min-width: 185px;
      font-family: $circularBold;
      color: $ctaColor;
      width: 100%;
      background-color: transparent;
      border: none;
      text-transform: uppercase;
      outline: none;
    }
    .toolgroup {
      .tooltipInstance {
        width: fit-content;
        max-width: 30%;
        pointer-events: auto !important;
        font-weight: normal;
        text-align: left;
        box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
        border-radius: 5px !important;
        background-color: $alertBackgroundColor !important;
        color: $alertFontColor !important;
        &::after {
          border: transparent !important;
          box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
          background-color: $alertBackgroundColor !important;
        }
        &.show {
          opacity: 1 !important;
        }
        @media screen and (max-width: 767px) {
          width: 50%;
        }
        a {
          color: $tooltipFontColor !important;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .tooltipText {
        font: 13px/1.5 $circularStd;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      font-size: 12px;
      gap: 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    gap: 10px;
    height: unset;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    gap: 0;
    border-radius: 15px 15px 0px 0px;
  }
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 760px;
  width: 90%;
  outline: none;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;

  .videoContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    align-items: flex-start;
    position: relative;
    gap: 10px;
    h2 {
      @include font-acumin-sb;
      font-size: 32px;
      align-items: center;
      color: $primaryColor;
      text-transform: uppercase;
      letter-spacing: 0;
    }
    p {
      font-family: Poppins, sans-serif;
      font-size: 12px;
      color: $sidebarPrimaryBackgroundColor;
      margin: 0;
      align-items: center;
    }
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      color: $primaryColor;
      cursor: pointer;
      font-size: 30px;
      line-height: 20px;
      padding: 20px;
    }
    .video {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 394px;
      iframe {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 767px) {
          iframe {
            height: 400px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        height: unset;
      }
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 400px;
    height: 380px;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
