@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0px 30px;
  max-width: 1500px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 20px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 0px;
  }
}

.mainContainer {
  background: $veryLight;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: unset;
      padding: 0px 15px;
    }
  }
  @media screen and (max-width: 767px) {
    max-height: calc(100vh - 133px);
  }
}

.lastContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  .backBtn {
    button {
      background: none;
      border: none;
      color: $ctaColor;
    }
  }
  .continueBtn {
    margin-left: 15px;
    button {
      background: $ctaColor;
      font-family: $circularBold;
      color: $fundingCtaFontColor;
      border: none;
      padding: 12px 40px;
      border-radius: 25px;
      letter-spacing: 2.8px;
      &:disabled {
        background-color: rgba($color: $ctaColorRgb, $alpha: 0.6);
        cursor: not-allowed;
      }
    }
  }
}

.onboardFlow {
  @media screen and (max-width: 767px) {
    .offerLetterContainer {
      order: 1;
    }
    .rightContainer {
      order: 2;
      margin-bottom: 0;
      .pdfContractContainer {
        position: relative;
        box-shadow: unset;
        background-color: transparent;
      }
      .mobileBtnContainer {
        overflow: visible;
        position: fixed;
        z-index: 4;
        height: unset;
        align-items: center;
        justify-content: space-between;
        min-width: auto;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: calc(100vh - 70px);
        padding: 20px;
        background-color: $white;
        border-radius: 15px 15px 0 0;
        box-shadow: 0 10px 51px rgba($color: $black, $alpha: 0.3);
      }
      .mainWarningDiv {
        order: 1;
      }
    }
  }
}

.mainContentContainer {
  display: flex;
  max-width: 1500px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  background-color: $veryLight;
  text-align: center;
  gap: 25px;
  max-height: calc(100vh - 200px);
  .offerLetterContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    background: $white;
    border-radius: 15px;
    font-family: $circularStd;
    border: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.3);
    overflow: hidden;
    .title {
      display: flex;
      align-items: center;
      padding: 35px 40px;
      .offerText {
        display: flex;
        flex-direction: column;
        h2 {
          font-size: 18px;
          font-family: $circularBold;
          text-align: left;
          letter-spacing: 0;
          margin-bottom: 0;
          span {
            font-family: $circularStd;
          }
        }
        span {
          font-size: 14px;
          text-align: left;
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      .expireDate {
        font-family: $circularBold;
        margin-left: auto;
        color: $helpTextColor;
        @media screen and (max-width: 767px) {
          margin-left: 0;
          font-size: 10px;
        }
      }
      .titleHeader {
        font-family: $circularBold;
        margin-right: auto;
        color: $primaryColor;
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }
      .actionText {
        display: none;
        @media screen and (max-width: 767px) {
          display: flex;
          text-align: left;
          font-size: 12px;
          margin-top: 10px;
        }
      }
      h2 {
        font-size: 18px;
        font-family: $circularBold;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .detailsContainer {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      padding: 5px 40px;
      flex-grow: 1;
      overflow-y: auto;
      .pdfLoading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        z-index: 99;
        color: $primaryColor;
        min-height: 500px;
      }
      .pdfContainer {
        max-height: calc(100vh - 200px);
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        div,
        canvas {
          max-width: 100% !important;
          height: auto !important;
          border: none;
          outline: none;
        }
        :global(.react-pdf__Page__textContent) {
          display: none;
        }
        :global(.react-pdf__Page__annotations) {
          display: none;
        }
        @media screen and (max-width: 1440px) {
          max-height: calc(100vh - 200px);
        }
        @media screen and (max-width: 767px) {
          max-height: calc(100vh - 440px);
        }
      }

      .offerDownload {
        margin-top: 20px;
        .offerFooter {
          display: flex;
          .btnLink {
            display: flex;
            cursor: pointer;
            margin-left: 362px;
            svg {
              width: 15px;
              margin-right: 10px;
              path {
                fill: $helpTextColor;
              }
            }
            @media screen and (max-width: 575px) {
              margin-left: 0;
            }
          }

          .secondaryOffer {
            display: flex;
            cursor: pointer;
            color: $helpTextColor;
            white-space: nowrap;
            @media screen and (max-width: 767px) {
              margin: unset;
            }
          }

          .goBackBtn {
            display: flex;
            cursor: pointer;
            color: $helpTextColor;
            text-decoration: underline;
            margin-right: auto;
            white-space: nowrap;
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 20px;
        }
      }

      .confirmCheck {
        margin: 10px 0;
        display: flex;
        align-items: center;
        .checkbox {
          color: $primaryColor !important;
          &:hover {
            background-color: transparent !important;
          }
        }
        label {
          margin: 0;
          margin-left: -11px;
        }
        .helpText {
          display: flex;
          align-items: center;
          cursor: pointer;
          line-height: 17px;
          color: $helpTextColor;
          @media screen and (max-width: 1440px) {
            font-size: 15px;
            line-height: 15px;
          }
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 0 20px 20px;
      }
    }
    @media screen and (max-width: 767px) {
      margin: unset;
    }
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    flex: 1.25 1;
    align-items: center;
    gap: 20px;
    overflow-y: auto;
    @media screen and (max-width: 767px) {
      overflow-y: unset;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 20px;
    row-gap: 20px;
    max-height: unset;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }
}

.actionContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--sidebar-primary-background-color);
  border-radius: 12px;
  font-family: $circularStd;
  color: $sidebarFontColor1;
  left: 0;
  bottom: 0;
  width: 100%;
  .actionHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 40px 20px;
    border-bottom: 1px solid $light;
    .depositActionText {
      color: $primaryColor;
    }
    .actionHeaderPost {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: $circularBold;
      .mailLogo {
        width: 30px;
        height: 30px;
        &.accentColor {
          svg path {
            fill: $progressAccentColor;
          }
        }
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: $primaryColor;
          }
        }
      }
      .postText {
        margin-bottom: 0px;
        color: $primaryColor;
        padding-left: 20px;
        text-align: left;
      }
    }
    .bankStatus {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: $marketplaceGreen, $alpha: 0.1);
        padding: 10px 20px;
        border-radius: 14px;
        color: $marketplaceGreen;
        font-size: 14px;
        font-family: $circularBold;
        margin-top: 30px;
        &.linkBreak {
          color: $marketplaceRed;
          background-color: rgba($color: $marketplaceRed, $alpha: 0.1);
          svg path {
            fill: $marketplaceRed;
          }
        }
        svg {
          margin-right: 10px;
          width: 18px;
          height: 18px;
          path {
            fill: $marketplaceGreen;
          }
        }
      }
    }
    h2 {
      font-size: 18px;
      font-family: $circularBold;
      text-align: center;
      letter-spacing: 0;
    }
    p {
      margin-top: 30px;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 0;
      &.primaryText {
        color: $primaryColor;
        font-family: $circularBold;
      }
      &.headerToolTip {
        font-size: 18px;
        font-family: $circularBold;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
  }
  .btnOnboarding {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .titleWithToolTip {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $white;
    flex-shrink: 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    letter-spacing: 0px;
    p {
      font-size: 22px;
      font-family: $circularBold;
      margin: 0;
      &.textSmall {
        font-size: 12px;
        font-family: $circular;
      }
      @media screen and (max-width: 1366px) {
        font-size: 18px;
      }
    }
    i {
      margin-left: 5px;
    }
    a {
      color: $sidebarFontColor1;
      text-decoration: underline;
      &:hover {
        color: $sidebarFontColor1;
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 18px;
      flex-wrap: wrap;
      white-space: unset;
    }
    @media screen and (max-width: 1366px) {
      font-size: 12px;
    }
  }
  .actionsHeaderContainer {
    display: flex;
    flex-direction: column;
    padding: 25px 28px;
    border-bottom: 1px solid $light;
    row-gap: 5px;
    h2 {
      width: 100%;
      font-size: 18px;
      line-height: 27px;
      font-family: $circularBold;
      text-align: left;
      letter-spacing: 0;
      color: $sidebarFontColor1;
      margin: 0;
      span {
        font-family: $circularStd;
        font-size: 14px;
      }
    }
    .expireDate {
      font-family: Poppins, sans-serif;
      text-align: left;
      color: $sidebarFontColor1;
      font-size: 12px;
      line-height: 18px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
        font-size: 10px;
      }
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      a.emailLink {
        display: inline;
        height: unset;
        width: unset;
        font-weight: 400;
        font-size: 16px;
        color: $sidebarFontColor1;
        text-decoration: underline;
        text-transform: none;
        cursor: pointer;
        @media screen and (max-width: 576px) {
          word-wrap: break-word;
        }
      }
    }
  }
  .pdfContractContainer {
    display: flex;
    flex-direction: column;
    padding: 35px 40px;
    align-items: center;
    .offerRightContainer {
      font-family: Poppins, sans-serif;
      font-size: 48px;
      width: 100%;
      display: inline-block;
      * {
        font-family: Poppins, sans-serif;
        letter-spacing: 1.92px;
      }
      .odometerContainer {
        overflow: hidden;
        height: 40px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 18px;
        @media screen and (max-width: 767px) {
          height: 24px;
        }
      }
      .menuBarScroll {
        width: 100%;
        overflow-y: auto;
        max-height: calc(100vh - 535px);
        min-height: 90px;
        @media screen and (max-width: 767px) {
          max-height: unset;
        }
      }
      .otherValues {
        font-size: 16px;
        width: 100%;
        font-weight: normal;
        * {
          align-items: center;
          font-family: Poppins, sans-serif;
          letter-spacing: 0px;
        }
        ul {
          margin: 0;
          padding-inline-start: 0px;
          padding: 10px 0;
          font-size: 17px;
          width: 100%;
          min-width: 65%;
          li {
            cursor: text;
            list-style: none;
            display: flex;
            align-items: center;
            padding: 3px 30px;
            gap: 10px;
            .fundingValues {
              font-family: Poppins, sans-serif;
              margin-left: auto;
              &.bold {
                font-family: $circularBold;
                font-size: 24px;
                font-weight: 700;
              }
            }
            .linkBtn {
              text-transform: uppercase;
              font-size: 14px;
              text-decoration: underline;
              background-color: transparent;
              border: none;
              color: inherit;
              text-align: right;
              padding: 0;
              cursor: pointer;
            }
            @media screen and (max-width: 767px) {
              align-items: flex-start;
            }
          }
        }
        li > div {
          position: relative;
          align-items: center;
          overflow: hidden;
          line-height: normal;
          bottom: -3px;
          font-size: 16px;
          white-space: nowrap;
          @media screen and (max-width: 767px) {
            white-space: unset;
            text-align: right;
          }
        }
      }
      .countText {
        display: flex;
        margin-top: 10px;
        font-family: Poppins, sans-serif;
        white-space: nowrap;
        letter-spacing: 0px;
        color: $sidebarFontColor2;
        font-size: 14px;
        &.bold {
          font-family: $circularBold;
        }
        .royaltyTooltip {
          font-size: 14px;
          font-family: $circularStd;
          letter-spacing: normal;
        }
        &.extraToolTipGap {
          gap: 4px;
        }
      }
      .tooltip {
        cursor: pointer;
        svg {
          path {
            fill: $sidebarTooltipIconColor;
          }
        }
      }
    }
    p {
      font-size: 14px;
      font-family: $circularStd;
    }
    &.getContractContainer {
      margin-top: auto;
      padding: 25px 20px;
    }
    .or {
      margin-bottom: 0;
    }
    .bold {
      font-family: $circularBold;
      margin-bottom: 0;
    }
    .pill {
      display: flex;
      align-items: center;
      column-gap: 5px;
      background-color: #ffefca;
      border-radius: 30px;
      font-size: 14px;
      padding: 3px 10px;
      margin-bottom: 15px;
      color: #954f0e;
      svg {
        width: 15px;
        flex-shrink: 0;
        path {
          fill: #954f0e;
        }
      }
    }
    .linkAccount {
      text-align: center;
      font-size: 14px;
    }
    .linkBankFooter {
      text-align: center;
      opacity: 1;
      font-size: 12px;
      color: $helpTextColor;
    }
    h2 {
      font-size: 18px;
      font-family: $circularBold;
      text-align: left;
      letter-spacing: 0;
      margin-bottom: 1rem;
    }
    .buttonNote {
      font-size: 10px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
        color: $sidebarPrimaryBackgroundColor;
      }
    }
    .bankInfoBtn {
      margin: 10px 0;
    }
  }
  .beforeAcceptOffer {
    padding: 0;
  }
  .totalEstimateContainer {
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
    padding: 10px 0px;
    margin-top: 10px;
    img {
      height: 15px;
      object-fit: contain;
      filter: grayscale(1) brightness(999) contrast(1);
      margin-left: auto;
      position: relative;
      margin-right: 5px;
    }
    .fundingValues {
      font-family: $circularBold;
      margin-left: auto;
      position: relative;
      bottom: -3px;
      &.bold {
        font-family: $circularBold;
        font-size: 24px;
      }
    }
    @media screen and (max-width: 767px) {
      border-bottom: none;
    }
  }
  .offerSummaryBtn {
    padding: 0px 28px;
  }
  .acceptContractContainer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 35px 40px;
    align-items: center;
    .actionHeaderPost {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: $circularStd;
      .mailLogo {
        width: 30px;
        svg {
          width: 100%;
          path {
            fill: $primaryColor;
          }
        }
        &.accentColor {
          svg path {
            fill: $sidebarTooltipIconColor;
          }
        }
      }

      .postText {
        margin-bottom: 0px;
        color: $primaryColor;
        padding-left: 20px;
        text-align: left;
      }
    }
  }
  button,
  a {
    font-weight: bold;
    width: 100%;
    border-radius: 25px;
    height: 40px;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    color: $ctaColor;
    letter-spacing: 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.secondary {
      border: 2px solid $ctaColor;
      margin-top: 20px;
      column-gap: 6px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    &.primary {
      border: 1px solid $ctaColor;
      background-color: $ctaColor;
      color: $fundingCtaFontColor;
      margin-top: 20px;
      white-space: nowrap;
      overflow: hidden;
      padding: 25px;
      &:disabled {
        color: $fundingCtaFontColor;
        background-color: $btnDeactivated;
      }
      @media screen and (max-width: 767px) {
        margin: unset;
        white-space: unset;
      }
    }
    &.linkBtn {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      color: #c92c2c;
      height: unset;
      letter-spacing: 0;
      margin-top: 20px;
      font-family: $circularBold;
      font-size: 14px;
    }
    &:disabled {
      color: $btnDeactivated;
      border: 1px solid $btnDeactivated;
      cursor: not-allowed;
    }
  }
  .mobileBtnContainer {
    .goBackBtn {
      display: none;
      cursor: pointer;
      color: $helpTextColor;
      text-decoration: underline;
      white-space: nowrap;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        display: flex;
      }
    }
    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
    @media screen and (max-width: 767px) {
      overflow: visible;
      position: fixed;
      z-index: 4;
      height: unset;
      align-items: center;
      justify-content: space-between;
      min-width: auto;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: calc(100vh - 70px);
      padding: 20px;
      background-color: $white;
      border-radius: 15px 15px 0 0;
      box-shadow: 0 10px 51px rgba($color: $black, $alpha: 0.3);
    }
  }
}

.menuContainer > div {
  max-width: 1500px !important;
}
.modalOverlay {
  display: flex;
  position: fixed;
  padding: 20px 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  overflow: auto;
  z-index: 4;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  .radioField {
    :global(.mb-4) {
      margin-bottom: 0px !important;
    }
  }
  .otherField {
    h2 {
      font-size: 18px;
      font-family: $circularBold;
      letter-spacing: 0px;
      margin-bottom: 25px;
      color: $darkBlueColor;
    }
    span {
      color: $primaryColor;
    }
    p {
      font-size: 14px;
      font-family: $circularStd;
    }
  }
  .modalBody {
    width: 100%;
    input.confirmNumber::placeholder {
      color: $darkGreyColor;
    }
    .bankLabel {
      font-size: 13px;
      color: $black;
      transform: translateY(0);
      margin: 0;
    }
    .searchBtn {
      position: absolute;
      padding: 2px 8px;
      font-size: 12px;
      right: 0;
      top: 0;
      transform: translateY(50%);
      width: fit-content;
      border-radius: 25px;
    }
    .bankSearchDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 15px;
      border-radius: 10px;
      min-height: 225px;
      background-color: rgba($color: $veryLight, $alpha: 0.7);
      margin-bottom: 5px;
      .loader {
        background-color: $veryLight;
      }
      .emptyBankDetail {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
          margin-top: 10px;
          color: $progressAccentColor;
        }
        svg {
          max-width: 55px;
          max-height: 55px;
          path:nth-child(2) {
            fill: rgba($color: $progressAccentColorRgb, $alpha: 0.8);
          }
        }
      }
      .bankDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        row-gap: 12px;
        p {
          margin-bottom: 0;
          font-family: $circularStd;
        }
        .infoLabel {
          font-family: $circularSemiBold;
          margin-bottom: 2px;
        }
      }
    }
    .microDepositText {
      text-align: left;
      opacity: 1;
      font-size: 12px;
      color: $helpTextColor;
      margin-top: 25px;
    }

    .hideLabel {
      opacity: 0;
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-family: $circularBold;
      letter-spacing: 0px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      font-family: $circularStd;
    }
    .modalBtn {
      display: flex;
      justify-content: flex-end;
      .plaidLink {
        background: $ctaColor;
        font-family: $circularBold;
        color: $fundingCtaFontColor;
        border: none !important;
        padding: 12px 40px;
        border-radius: 25px;
        letter-spacing: 2.8px;
        &:disabled {
          background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
          cursor: not-allowed;
          border: none;
          color: $fundingCtaFontColor;
        }
      }
    }
    .countryInput {
      > div {
        padding-top: 0px;
      }
      input {
        width: 50%;
        font-size: 14px;
      }
    }
  }
}
.countryModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  padding: 40px;
  background-color: #fff;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 20px;
  form {
    width: 100%;
  }
  .header {
    text-align: left;
    font-size: 18px;
    font-family: $circularBold;
    color: $primaryColor;
    width: 100%;
    &.depositHeader {
      padding-bottom: 25px;
    }
  }
  .textarea {
    height: 200px;
    font-family: $circularStd;
    font-size: 14px;
    color: $black;
    padding: 15px;
    border: 1.5px solid;
    border-radius: 7px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .modalBtn {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: rgba($color: $ctaColorRgb, $alpha: 1);
      padding: 10px 20px;
      border-radius: 25px;
      border: none;
      color: $fundingCtaFontColor;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      font-family: $circularBold;
      font-size: 14px;
      cursor: pointer;
      &:disabled {
        background-color: rgba($color: $ctaColorRgb, $alpha: 0.6);
        cursor: not-allowed;
      }
      &.backButton {
        background-color: $white;
        color: $black;
        text-transform: none;
      }
    }
    &.fullWidth {
      width: 100%;
    }
  }
  &.depositModal {
    max-width: 510px;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    padding: 30px;
  }
}

.onboardingContainer {
  display: flex;
  flex-direction: column;
  background-color: $appPrimaryBackgroundColor;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  &.backgroundImage {
    background-image: url("../../../img/ackBG.png");
    background-size: cover;
  }
  .headerContainer {
    position: absolute;
    padding: 20px;
    width: 100%;
    img {
      max-height: 50px;
      object-fit: contain;
    }
    @media screen and (max-width: 1024px) {
      position: relative;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }
  .subContainer {
    display: flex;
    margin: auto;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    padding: 40px;
    background-color: $white;
    border-radius: 12px;
    @media screen and (max-height: 800px) {
      margin: 60px auto;
    }
    @media screen and (max-width: 767px) {
      margin: 20px auto 60px;
      padding: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
  }
}

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    :global(.form-group) {
      :global(.mb-4) {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0;
        label {
          position: relative;
          top: unset;
          left: unset;
          padding: unset;
          font-size: 14px;
          pointer-events: all;
          transform: unset;
          color: initial;
          order: 1;
          margin: 0;
        }
        input::placeholder {
          color: rgba($color: $primaryColorRgb, $alpha: 0.3);
        }
        :global(.invalid-feedback) {
          font-size: 12px;
          bottom: -18px;
        }
      }
    }
    .formTitle {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: $circularBold;
      letter-spacing: 0;
      .logoContainer {
        display: flex;
        align-items: center;
        margin-right: 8px;
        justify-content: center;
        background-color: $primaryColor;
        height: 28px;
        width: 28px;
        border-radius: 6px;
        svg {
          height: 18px;
          path {
            fill: $white;
          }
        }
      }
      img {
        height: 27px;
        width: 27px;
        object-fit: cover;
        object-position: left;
        margin-right: 8px;
        &.hide {
          display: none;
        }
      }
      p {
        margin: 0;
        span {
          color: $primaryColor;
        }
      }
    }
    .contentText,
    .labelTitle {
      font-size: 14px;
    }

    label {
      .smallLabel {
        opacity: 0;
        display: block;
        font-size: 10px;
        line-height: 11px;
        transition: opacity 0.2s ease-in-out;
      }
    }
    .smallText {
      font-size: 10px;
    }
    .w8Container {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      align-items: flex-end;
      margin-bottom: 20px;
      .linkText {
        font-size: 10px;
        color: #2266ff;
        text-decoration: underline;
        cursor: pointer;
      }
      .fileUpload {
        position: relative;
        display: flex;
        cursor: pointer;
        overflow: hidden;
        margin-left: auto;
        input {
          display: none;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        span {
          font-size: 12px;
          color: $primaryColor;
          text-decoration: underline;
          white-space: nowrap;
        }
        svg {
          width: 13px;
          margin-right: 5px;
          flex-shrink: 0;
          path {
            fill: $primaryColor;
          }
        }
        &.publicity {
          margin-left: unset;
        }
        @media screen and (max-width: 767px) {
          margin-left: unset;
          margin-top: 0;
        }
      }
      .errorClass {
        bottom: 0;
        left: 0;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 0;
        align-items: flex-start;
      }
    }
    .filePreview {
      display: flex;
      align-items: center;
      font-size: 12px;
      justify-content: flex-end;
      word-break: break-word;
      svg {
        width: 13px;
        margin-right: 5px;
        flex-shrink: 0;
        path {
          fill: $black;
        }
      }
      &.publicity {
        justify-content: flex-start;
      }
      @media screen and (max-width: 767px) {
        justify-content: flex-start;
      }
    }
    .separator {
      padding-top: 20px;
      border-top: 1px solid $veryLight;
    }
    .btnContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .stepMobileContainer {
        display: flex;
        align-items: center;
        button.secondary {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
            margin-right: 0;
            margin-left: auto;
          }
        }
        @media screen and (max-width: 767px) {
          margin-top: 20px;
          width: 100%;
          justify-content: center;
        }
      }
      .stepDotsContainer {
        display: flex;
        align-items: center;
        margin-right: auto;
        span {
          display: flex;
          height: 16px;
          width: 16px;
          flex-shrink: 0;
          border-radius: 10px;
          border: 1px solid $primaryColor;
          margin-right: 5px;
          &.active::after {
            content: " ";
            display: block;
            flex-shrink: 0;
            height: 10px;
            width: 10px;
            background-color: $primaryColor;
            border-radius: 10px;
            margin: auto;
          }
        }
        @media screen and (max-width: 767px) {
          margin-right: unset;
        }
      }
      button {
        padding: 12px 20px;
        border: none;
        border-radius: 25px;
        background-color: $ctaColor;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        font-family: $circularBold;
        font-size: 14px;
        transition:
          width 0.3s,
          background-color 0.3s;
        max-width: 200px;
        width: 100%;
        &:disabled {
          background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
          cursor: not-allowed;
        }
        &.secondary {
          border: none;
          background-color: transparent;
          color: $ctaColor;
          font-family: $circularStd;
          letter-spacing: 0;
          text-transform: unset;
          margin-right: 6px;
          width: unset;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        @media screen and (max-width: 767px) {
          max-width: unset;
        }
      }
      .nextBackContainer {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
    }
    .showPlaceholder {
      margin-top: -30px;
      input::placeholder {
        color: $darkGreyColor;
      }
    }
    :global(.invalid-feedback.position-absolute) {
      margin-right: 0 !important;
      font-size: 11px;
    }
  }
  .sentenceCase {
    :global(label.floating) {
      text-transform: none;
    }
  }
}

.fileUploadContainer {
  display: flex;
  position: relative;
  align-items: flex-end;
  gap: 15px;
  border-bottom: 1px solid $light;
  padding: 20px 0;
  .labelTitle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    p {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
.contentTextTitle {
  font-size: 12px;
  border-bottom: 1px solid $light;
  padding-bottom: 5px;
}

.helperContainer {
  & > input,
  div {
    &:first-child {
      order: 1;
    }
  }
}

.helperLabel {
  font-size: 10px;
  color: $primaryColor;
  margin: 0;
}

.closeBtn {
  border: none;
  background-color: transparent;
  color: $primaryColor;
  font-size: 30px;
  line-height: 18px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -20px;
  right: -20px;
  font-family: $circularLight;
  padding: 0;
}

.plaidLink1 {
  display: none;
}

.mainFooterContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  max-width: 1500px;
  padding: 25px 0px;
  .footerTooltipContainer {
    display: flex;
    width: 100%;
    background-color: rgba($helpTextColorRgb, 0.1);
    border-radius: 13px;
    padding: 15px;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
      fill: $sidebarPrimaryBackgroundColorRgb;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .toolTipContent {
      display: flex;
      text-align: start;
      color: $helpTextColor;
      padding: 0 11px;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      line-height: 19.88px;
      margin: unset;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0px;
    margin-bottom: 130px;
  }
}
